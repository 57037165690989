import React, { useState } from 'react'
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevronDown from "./chevron-down.svg";
import styles from "./styles.module.css";
import cover from "./../../Assets/Images/cover.png"
import mission from "./../../Assets/Images/mission.png"
import activities from "./../../Assets/Images/activities.png"
import gallery from "./../../Assets/Images/gallery.png"
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import { Link } from 'react-scroll';
export const Home = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_o19drtm",
                "template_yzo00qe",
                e.target,
                "AeB-rqbj0aVlm6cUB"
            )
            .then(
                (result) => {
                    toast.success("Message Sent Successfully");
                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    });
                    console.log(result.text);
                },
                (error) => {
                    toast.error("Message Sending Failed !! Try Again Later !!");
                    console.log(error.text);
                }
            );
    };

  const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className={styles.chevron} src={chevronDown} alt="Chevron Down" />
      </>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) =>
        `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
    }}
    contentProps={{ className: styles.itemContent }}
    panelProps={{ className: styles.itemPanel }}
  />
);


  return (
    <div>
      <section id='home' className='flex flex-col justify-between pb-20'>
        <div className='max-w-[1440px] mx-auto flex justify-center items-center mt-[30px] md:mt-[100px]'>
          <div className='px-2'>
            <h1 className='text-[48px] font-bold text-center'>Empowering Communities Through Playing, <br />Learning, and Service</h1>
            <p className='text-[18px] text-center'>Join us in making a difference in the lives of children and families.</p>
            <div className='flex gap-5 justify-center items-center mt-[30px]'>
              <Link to="contact" className="border-black border-[2px] border-solid rounded-md text-bold hover:scale-105 hover:transition-transform duration-200 flex items-center justify-center px-10 py-3 hover:bg-black hover:text-white">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className='w-[99vw] mt-[80px]'>
          <img src={cover} alt="" className='w-full'/>
        </div>
      </section>
      <section id='mission' className='lg:flex max-w-[1245px] mx-auto mt-[80px] justify-between items-center gap-5'>
        <div className='lg:w-1/2 px-3'>
          <div>
            <h3 className='text-[40px] font-bold relative'>Our Mission <div className="absolute left-[70px] bottom-[25px] z-[-1]" style={{
              width: "220px",
              height: "55px",
              backgroundColor : "#FFD400",
              clipPath: 'polygon(0 1%, 85% 0, 100% 100%, 0% 100%)'
            }}></div></h3>
          </div>
          <p className='text-[18px]'>Community Service Foundation is dedicated to providing counseling, foster care and other services to help young people and their families to grow and change through restorative practices.</p>
          <div className='flex mt-[20px] gap-5'>
            <div>
              <h3 className='text-[24px] font-bold'>Education</h3>
              <p className='text-[18px]'>We believe in the power of play as a fundamental right of childhood. Play is not only enjoyable but also crucial for cognitive, emotional, and social development.</p>
            </div>
            <div>
              <h3 className='text-[24px] font-bold'>Community Services</h3>
              <p className='text-[18px]'>Education is the key to unlocking opportunities and breaking the cycle of poverty. We provide educational resources, workshops.</p>
            </div>
          </div>
          <div className='flex mt-[20px] gap-5'>
            <div>
              <h3 className='text-[24px] font-bold'>Promote Play</h3>
              <p className='text-[18px]'>We believe in the power of play as a fundamental right of childhood. Play is not only enjoyable but also crucial for cognitive, emotional, and social development.</p>
            </div>
            <div>
              <h3 className='text-[24px] font-bold'>Community Services</h3>
              <p className='text-[18px]'>Service is at the core of our foundation's ethos. We believe in the inherent value of giving back to the community and helping those in need.</p>
            </div>
          </div>
        </div>
        <div className='lg:w-1/2 flex justify-center items-center mt-10 md:mt-0 px-2'>
          <img src={mission} alt="" />
        </div>
      </section>
      <section id='activities' className='flex flex-col-reverse lg:flex-row max-w-[1245px] mx-auto mt-[80px] justify-between items-center gap-5'>
        <div className='lg:w-1/2 flex justify-center items-center px-2'>
          <img src={activities} alt="" />
        </div>
        <div className='lg:w-1/2 px-3'>
          <div>
            <h3 className='text-[40px] font-bold relative'>Our Activities <div className="absolute left-[70px] bottom-[25px] z-[-1]" style={{
              width: "220px",
              height: "55px",
              backgroundColor : "#FFD400",
              clipPath: 'polygon(0 1%, 85% 0, 100% 100%, 0% 100%)'
            }}></div></h3>
          </div>
          <p className='text-[18px]'>Community Service Foundation is dedicated to providing counseling, foster care and other services to help young people and their families to grow and change through restorative practices.</p>
          <Accordion>
            <AccordionItem header="Educational Workshops:">
               We organize a variety of educational workshops designed to cater to the diverse needs and interests of our community members. These workshops cover topics such as literacy, STEM (Science, Technology, Engineering, and Mathematics), arts and crafts, life skills, and more. Led by experienced educators and professionals, these interactive sessions provide valuable learning opportunities in a fun and engaging environment.
            </AccordionItem>
            <AccordionItem header="Community Events:">
               We organize a variety of educational workshops designed to cater to the diverse needs and interests of our community members. These workshops cover topics such as literacy, STEM (Science, Technology, Engineering, and Mathematics), arts and crafts, life skills, and more. Led by experienced educators and professionals, these interactive sessions provide valuable learning opportunities in a fun and engaging environment.
            </AccordionItem>
            <AccordionItem header="Service Projects:">
               We organize a variety of educational workshops designed to cater to the diverse needs and interests of our community members. These workshops cover topics such as literacy, STEM (Science, Technology, Engineering, and Mathematics), arts and crafts, life skills, and more. Led by experienced educators and professionals, these interactive sessions provide valuable learning opportunities in a fun and engaging environment.
            </AccordionItem>
            <AccordionItem header="Youth Programs:">
               We organize a variety of educational workshops designed to cater to the diverse needs and interests of our community members. These workshops cover topics such as literacy, STEM (Science, Technology, Engineering, and Mathematics), arts and crafts, life skills, and more. Led by experienced educators and professionals, these interactive sessions provide valuable learning opportunities in a fun and engaging environment.
            </AccordionItem>
          </Accordion>
        </div>
      </section>
      <section id='gallery' className='max-w-[1245px] mx-auto mt-[80px] flex flex-col justify-center items-center'>
          <div>
            <h3 className='text-[40px] font-bold relative'>Media Gallery <div className="absolute left-[115px] bottom-[25px] z-[-1]" style={{
              width: "190px",
              height: "55px",
              backgroundColor : "#FFD400",
              clipPath: 'polygon(0 1%, 85% 0, 100% 100%, 0% 100%)'
            }}></div></h3>
          </div>
          <div className='mt-[40px]'>
            <img src={gallery} alt="" />
          </div>
      </section>
      <section id='contact' className='max-w-[1245px] mx-auto mt-[80px] flex flex-col justify-center items-center mb-20'>
          <div className='px-3'>
            <h3 className='text-[40px] font-bold relative'>Want to join? Get in Touch <div className="absolute left-[115px] lg:bottom-[25px] bottom-[80px] z-[-1] lg:w-[470px] w-[250px] h-[55px]" style={{
              backgroundColor : "#FFD400",
              clipPath: 'polygon(0 1%, 85% 0, 100% 100%, 0% 100%)'
            }}></div></h3>
          </div>
          <div className='mt-[40px] w-2/3 mx-auto'>
            <form onSubmit={handleSubmit} className='flex flex-col justify-center items-center'>
                <div className='lg:flex gap-10 w-full'>
                    <div className='flex flex-col lg:w-1/2'>
                        <label htmlFor="name">Your Name</label>
                        <input
                            className='border-2 border-[#AFAFAF] rounded-md p-3'
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='Enter your full name'
                            required
                        />
                    </div>
                    <div className='flex flex-col lg:w-1/2'>
                        <label htmlFor="email">Your Email</label>
                        <input
                            className='border-2 border-[#AFAFAF] rounded-md p-3'
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='Enter your email'
                            required
                        />
                    </div>
                </div>
                <div className="mt-[20px] flex flex-col w-full">
                    <label htmlFor="message">Message</label>
                    <textarea
                        className='border-2 border-[#AFAFAF] rounded-md p-3'
                        name="message"
                        id="message"
                        value={formData.message}
                        onChange={handleChange}
                        cols="30"
                        rows="10"
                        placeholder='Enter your Message'
                        required
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="border-black border-[2px] border-solid rounded-md text-bold hover:scale-105 hover:transition-transform duration-200 flex items-center justify-center px-10 py-3 hover:bg-black hover:text-white w-[200px] mt-[20px]"
                >
                    Send Message
                </button>
            </form>
          </div>
      </section>
    </div>
  )
}
