import React, { useState } from 'react'
import logo from '../../Assets/Images/logo.png'
import { NavLink } from 'react-router-dom'
import { IoMenu } from 'react-icons/io5'
import { RiMenuFoldFill } from 'react-icons/ri'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [showDropdown, setShowDropdown] = useState(false)

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    return (
        <nav className="border-b-2 px-3 sticky top-0 bg-white z-[999]">
            <div className="container mx-auto flex justify-between items-center py-4">
                <div>
                    <NavLink to="/" className="flex items-center">
                        <img src={logo} alt="Logo" className="h-[80px] mr-2" />
                    </NavLink>
                </div>
                <div className="lg:hidden">
                    <button className="text-2xl" onClick={toggleDropdown}>
                        {showDropdown ? <RiMenuFoldFill /> : <IoMenu />}
                    </button>
                </div>
                <div className="hidden lg:flex lg:items-center">
                    <ul className="flex space-x-6">
                        <NavItem to="home" text="Home" />
                        <NavItem to="mission" text="Mission" />
                        <NavItem to="activities" text="Activities" />
                        <NavItem to="gallery" text="Gallery" />
                        <NavItem to="contact" text="Contact Us" />
                    </ul>
                </div>
            </div>
            {showDropdown && (
                <div className="lg:hidden bg-gray-200">
                    <ul className="flex flex-col items-center">
                        <NavItem to="home" text="Home" />
                        <NavItem to="mission" text="Mission" />
                        <NavItem to="activities" text="Activities" />
                        <NavItem to="gallery" text="Gallery" />
                        <NavItem to="contact" text="Contact Us" />
                    </ul>
                </div>
            )}
        </nav>
    )
}

const NavItem = ({ to, text }) => (
    <li className="">
        <Link to={to} offset={-200} className="block py-2 px-4">
            {text}
        </Link>
    </li>
)

export default Navbar
