import React from 'react'
import logo from '../../Assets/Images/logo.png'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <section className="py-16 border-t-2">
            <div className="max-w-[1400px] mx-auto  lg:flex justify-between px-4">
                <div className="mb-[20px] md:mb-0">
                    <img src={logo} alt="" />
                    <p className="text-[18px]">
                        Join us in making a difference in the lives of <br />{' '}
                        children and families.
                    </p>
                </div>
                <div className="mt-[20px] md:mt-0 flex flex-col gap-[10px]">
                    <h3 className="text-[18px] font-medium">Quick Links</h3>
                    <Link>Activities</Link>
                    <Link>Gallery</Link>
                    <Link>About Us</Link>
                    <Link>Contact Us</Link>
                </div>
                <div className="mt-[20px] md:mt-0">
                    <h3 className="text-[18px] font-medium">Contact Us</h3>
                    <p>
                        Security Building, Floor 8 ballroom <br />
                        222 N Central Avenue Phoenix,
                        <br />
                        AZ 85004 <br />
                        +1(480)-382-6330
                    </p>
                </div>
            </div>
        </section>
    )
}
